import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import "./../css/BecomeMember.css"
import serialize from 'form-serialize';
import axios from "axios"

function BecomeMemberTemplate(props) {
    console.log(props)
    let data = props.data;

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [emailResponse, setEmailResponse] = useState("")
    const [terms, setTerms] = useState(false)


    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function partnerMessage(e) {
        e.preventDefault();
        setLoading(true)

        let form = document.querySelector('#partnerForm');
        let data = serialize(form, { hash: true });

        data = {
            ...data,
            language: props.pageContext.language,
        }
        console.log(data)
        if (data.fullname) {
            if (data.email) {
                if (data.telephone) {
                    if (data.address) {
                        if (data.company) {
                            if (terms) {
                                setError("")
                                axios.post(`https://admin.dkaa.gr/wp-json/myplugin/v1/contactEmail`, data)
                                    .then(function (response) {
                                        setEmailResponse(response.data)
                                        setLoading(false)

                                    })
                                    .catch(function (error) {
                                        // handle error
                                        setLoading(false)
                                    })
                            } else {
                                setError('Παρακαλούμε αποδεχθείτε τους Όρους χρήσης')
                                setLoading(false)

                            }
                        } else {
                            setError('Παρακαλούμε συμπληρώστε το πεδίο Εταιρεία')
                            setLoading(false)

                        }
                    } else {
                        setError('Παρακαλούμε συμπληρώστε το πεδίο Διεύθυνση')
                        setLoading(false)

                    }
                } else {
                    setError('Παρακαλούμε συμπληρώστε το Τηλέφωνο επικοινωνίας')
                    setLoading(false)

                }
            } else {
                setError('Παρακαλούμε συμπληρώστε το πεδίο Email')
                setLoading(false)
            }
        } else {
            setError('Παρακαλούμε συμπληρώστε το Ονοματεπώνυμο')
            setLoading(false)
        }
        console.log(data)
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.allWpPage.nodes[0].aboutPageAcf.introductionBackgroundImage} title={data.allWpPage.nodes[0].aboutPageAcf.introductionHeader} />
                    </section> */}

                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <section className="px-4">
                        <div className="container-cs mx-auto py-16">
                            <h1 className="text-center dk-title">
                                <span>{data.allWpPage.nodes[0].memberAcf.member.title}</span>
                                <span>{data.allWpPage.nodes[0].memberAcf.member.subtitle}</span>
                            </h1>
                            <div className="flex flex-wrap mt-8">
                                {data.allWpPage.nodes[0].memberAcf.member.description && data.allWpPage.nodes[0].memberAcf.member.description.map((item, i) => (
                                    <div className={`w-full md:w-1/2 member-editor ${i === 0 && "pr-0 md:pr-4"}`} key={`member-editor-${i}`} dangerouslySetInnerHTML={{ __html: item.content }} />
                                ))}
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className="container-cs mx-auto py-16 mb-16" style={{ backgroundColor: "#F8F4F0" }}>
                            <h2 className="text-center dk-title2">
                                <span>{data.allWpPage.nodes[0].memberAcf.form.title}</span>
                                <span>{data.allWpPage.nodes[0].memberAcf.form.subtitle}</span>
                            </h2>

                            { (emailResponse === "") && (
                                <div>
                                    <p className="text-center pt-6" style={{ color: "#616161", fontSize: "1.13rem" }}>{data.allWpPage.nodes[0].memberAcf.form.description}</p>
                                    <div className="py-12 mx-auto" style={{ maxWidth: "750px" }}>
                                        <form className="partner-form px-8 md:px-0" id="partnerForm" onSubmit={(e) => partnerMessage(e)}>
                                            <div className="flex flex-wrap mb-4">
                                                <label className="w-full" for="fullname">{data.allWpPage.nodes[0].memberAcf.form.fullName}</label>
                                                <input className="w-full  mt-4 md:mt-0" id="fullname" name="fullname" type="text" />
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                                <label className="w-full" for="email">{data.allWpPage.nodes[0].memberAcf.form.email}</label>
                                                <input className="w-full mt-4 md:mt-0" id="email" name="email" type="email" />
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                                <label className="w-full " for="telephone">{data.allWpPage.nodes[0].memberAcf.form.phone}</label>
                                                <input className="w-full mt-4 md:mt-0" id="telephone" name="telephone" />
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                                <label className="w-full " for="address">{data.allWpPage.nodes[0].memberAcf.form.address}</label>
                                                <input className="w-full mt-4 md:mt-0" id="address" name="address" />
                                            </div>
                                            <div className="flex flex-wrap mb-4">
                                                <label className="w-full " for="company">{data.allWpPage.nodes[0].memberAcf.form.company}</label>
                                                <input className="w-full mt-4 md:mt-0" id="company" name="company" />
                                            </div>
                                            <div className="flex items-center">
                                                <input type="checkbox" onClick={() => setTerms(!terms)} style={{ width: "15px" }} />
                                                <div className="pl-4 terms-editor" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].memberAcf.form.terms }} />
                                            </div>
                                            <div className="text-red-500">{error}</div>

                                            <div className="pt-8 flex justify-center">
                                                <button
                                                    type="submit"
                                                    className="bttn-default"
                                                >
                                                    {data.allWpPage.nodes[0].memberAcf.form.send}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}


                            {
                                (emailResponse !== "") && (
                                    <div className="text-center mt-8" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                                )
                            }

                        </div>
                    </section>

                </div>
            </Layout>
        </div>
    );
}

export default BecomeMemberTemplate

export const pageQuery = graphql`query GET_BECOME_MEMBER($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                sourceUrl
                }
            }
            content
            breadcrumbAcf {
                breadcrumbName
                
            }
            memberAcf {
                member{
                    title
                    subtitle
                    description{
                        content
                    }
                }
                form{
                    title
                    subtitle
                    description
                    fullName
                    email
                    phone
                    address
                    company
                    terms
                    send
                }
            }
        }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
